<template>
   <v-form ref="other-form" @submit.prevent="formSubmit" id="test">
      <v-row>
         <v-col cols="12">
            <h3>Others</h3>
         </v-col>
         <!-- <v-col cols="12">
            <v-card>
               <v-card-text>
                  <v-row>
                     <v-col md="6" cols="12">
                        <FormControl v-model="upgradeToVip" label="Upgrade to VIP" inputType="string" :required="true" />
                     </v-col>
                     <v-col md="6" cols="12">
                        <FormControl v-model="upgradeToSeniorTechnician" label="Upgrade to senior technician (job)" inputType="string" :required="true" />
                     </v-col>
                     <v-col md="6" cols="12">
                        <FormControl v-model="creditRatio" label="Credit ratio ($N to 1 credit)" inputType="string" :required="true" />
                     </v-col>
                  </v-row>
               </v-card-text>
            </v-card>
         </v-col> -->
         <v-col md="12" cols="12">
            <v-card>
               <v-card-title><h5>Techniciannical report management</h5></v-card-title>
               <v-card-text>
                  <v-row>
                     <v-col md="6" cols="12" v-for="(row, index) in data.checklist" :key="`checklist-item-${index}`">
                        <v-row>
                           <v-col md="9" cols="12">
                              <FormControl inputType="string" v-model="row.text" label="Check-list" :required="true" />
                           </v-col>
                           <v-col md="3" cols="12" class="d-flex">
                              <v-btn color="error" class="align-self-center" @click="removeRow(index)" :disabled="isFetching">Remove</v-btn>
                           </v-col>
                        </v-row>

                     </v-col>
                     <v-col cols="12">
                        <v-btn color="secondary" :disabled="isFetching" @click="createRow()">Add</v-btn>
                     </v-col>
                  </v-row>
                  <v-row>
                     <v-col md="12" cols="12">
                        <FormControl v-model="data.remark" label="Remark" inputType="string" :required="true" />
                     </v-col>
                     <v-col md="12" cols="12">
                        <FormControl v-model="data.terms" label="Terms" inputType="textarea" :rows="8" :required="true" />
                     </v-col>
                  </v-row>
               </v-card-text>
            </v-card>
         </v-col>
         <v-col cols="12">
            <v-btn color="purple darken-3 white--text" 
               class="px-8" 
               large 
               :disabled="isFetching" 
               @click="downloadPdf">Download check-list report</v-btn>
         </v-col>
         <v-col cols="12">
            <v-btn color="primary" class="px-8" large :disabled="isFetching" type="submit">Save</v-btn>
         </v-col>
         <!-- <iframe id="ifrm" :src="this.pdfPreview" width="100%" height="800"></iframe> -->
      </v-row>
   </v-form>
</template>

<script>
import FormControl from '@/components/form/FormControl'
import { mapActions, mapState } from 'vuex'
import createReport from '@/components/pdf/techniciannicalReport'
import _ from 'lodash'

export default {
   name: 'Others',
   components: {
      FormControl,
   },
   data() {
      return {
         isNew: true,
         data: {
            id: null,
            checklist: [],
            remark: '',
            terms: '',
         },
         pdfPreview: '',
      }
   },
   computed: {
      ...mapState({
         isFetching: (state) => state.request.isFetching,
         isSuccess: (state) => state.request.isSuccess,
         isFail: (state) => state.request.isFail,
      }),
   },
   // watch: {
   //    remark: 'createPdf',
   //    terms: 'createPdf',
   //    checklist: {
   //       handler: val => {
   //          console.log('checklist', val)
   //       },
   //       deep: true,
   //    },
   // },
   methods: {
      ...mapActions(['sendRequest'], 'request'),
      ...mapActions(['setDialogMessage', 'setShowDialog']),
      
      async initData() {
         const resp = await this.sendRequest({ type: 'get_checklist', data: {} })
         if (!resp) return
         this.isNew = false
         this.data = {
            ...this.data,
            id: resp.data.id,
            checklist: _.map(resp.data.checklist, text => ({ text })),
            remark: resp.data.remark,
            terms: resp.data.terms,
         }
      },
      async formSubmit() {
         console.log('save')
         const isValid = this.$refs['other-form'].validate()
         if (isValid) {
            const action = this.isNew ? this.createItem : this.updateItem
            const resp = await action()
            this.setDialogMessage({ 
               message: resp ? 'Successful' : 'Failed', 
               isError: !resp,
            })
            this.setShowDialog(true)
            if (!!resp && this.isNew) {
               this.isNew = false
               this.data.id = resp.data.id
               this.initData()
            }
         }
      },
      async createItem() {
         console.log('create')
         const formdata = {
            status: 'active',
            data_status: 'active',
            checklist: _.map(this.data.checklist, item => item.text),
            remark: this.data.remark,
            terms: this.data.terms,
         };
         return await this.sendRequest({ type: 'add_checklist', data: formdata })
      },
      async updateItem() {
         console.log('update');
         const formdata = {
            data_type: 'checklist_data',
            id: this.data.id,
            checklist: _.map(this.data.checklist, item => item.text),
            remark: this.data.remark,
            terms: this.data.terms,
         };
         return await this.sendRequest({ type: 'update_checklist', data: formdata });
      },
      
      createRow() {
         this.$set(this.data.checklist, this.data.checklist.length, { text: '' })
      },
      
      removeRow(index) {
         this.$delete(this.data.checklist, index)
      },

      async createPdf() {
         const data = {
            ...this.data,
            checklist: _.map(this.data.checklist, item => item.text)
         }
         const { url, downloadPdf } = await createReport(data)
         this.pdfPreview = url
         this.download = downloadPdf
      },
      async downloadPdf() {
         await this.createPdf()
         this.download()
      }
   },
   async mounted() {
      await this.initData()
      // await this.createPdf()
   }
}
</script>
