import { PDFDocument } from 'pdf-lib'
import fontkit from '@pdf-lib/fontkit'
import _ from 'lodash'

const v = {
    // pageWidth: 594,
    // pageHeight: 840,
    pagePadding: 30,
    logoSize: 12,
    fontSize: 10,
    tittleFontSize: 16,
    listItemWidth: 200,
    listGap: 40,
    lineWidth: 150,
    lineSize: 1,
    remarkY: 400,
    termsY: 300,
    footerY: 60, 
}

const createPdf= async data => {
    const pdf = await PDFDocument.create()
    // font
    const fontBytes = await fetch('/fonts/NotoSansTC-Regular.otf').then(res => res.arrayBuffer())
    const fontBoldBytes = await fetch('/fonts/NotoSansTC-Bold.otf').then(res => res.arrayBuffer())
    pdf.registerFontkit(fontkit)
    const font = await pdf.embedFont(fontBytes)
    const fontBold = await pdf.embedFont(fontBoldBytes)

    // page
    const page = pdf.addPage()
    const { width, height } = page.getSize()
    const draw = {
        title: { font: fontBold, size: v.tittleFontSize },
        text: { font, size: v.fontSize, lineHeight: v.fontSize * 1.5, wordBreaks: [''] },
    }
    const bgBytes = await fetch(require('@/assets/images/misc/checklist-bg.jpg')).then((res) => res.arrayBuffer())
    const bg = await pdf.embedJpg(bgBytes)
    page.drawImage(bg, {
        x: 0,
        y: 0,
        width: bg.width,
        height: bg.height,
    })

    // logo
    const logoBytes = await fetch(require('@/assets/images/logos/logo.jpg')).then((res) => res.arrayBuffer())
    const logo = await pdf.embedJpg(logoBytes)
    page.drawImage(logo, {
        x: 180,
        y: height - v.pagePadding - 30,
        width: 30,
        height: 30,
    })
    page.drawText('Forward Professional & Creative Limited\n前線專業創意有限公司', {
        x: 220,
        y: height - v.pagePadding - v.fontSize,
        ...draw.text,
    })

    // checklist
    page.drawText('Technical check list', {
        ...draw.title,
        x: 220,
        y: 720,
    })
    let listLeft = { x: (width - v.listGap) / 2 - v.listItemWidth, y: 680 }
    let listRight = { x: (width + v.listGap) / 2, y: 680 }
    _.forEach(data.checklist, (text, index) => {
        if (index % 2 === 0) {
            // left
            page.moveTo(listLeft.x, listLeft.y)
            page.drawSquare({
                size: v.fontSize,
                borderWidth: 1,
                opacity: 0,
            })
            page.moveUp(1)
            page.moveRight(30)
            page.drawText(text, {
                ...draw.text,
                maxWidth: v.listItemWidth,
            })
            listLeft = { ...listLeft, y: listLeft.y - v.listGap }
        } else {
            // right
            page.moveTo(listRight.x, listRight.y)
            page.drawSquare({
                size: v.fontSize,
                borderWidth: 1,
                opacity: 0,
            })
            page.moveUp(1)
            page.moveRight(30)
            page.drawText(text, {
                ...draw.text,
                maxWidth: v.listItemWidth,
            })
            listRight = { ...listRight, y: listRight.y - v.listGap }
        }
    })

    // remark
    page.drawText('Remark', {
        ...draw.title,
        x: v.pagePadding,
        y: v.remarkY,
    })
    page.drawText(data.remark, {
        ...draw.text,
        x: v.pagePadding,
        y: v.remarkY - 30,
        maxWidth: width - v.pagePadding * 2,
    })
    
    // terms
    page.drawText('Terms and conditions', {
        ...draw.title,
        x: v.pagePadding,
        y: v.termsY,
    })
    page.drawText(data.terms, {
        ...draw.text,
        x: v.pagePadding,
        y: v.termsY - 30,
        maxWidth: width - v.pagePadding * 2,
    })

    // footer
    page.drawLine({
        start: { x: v.pagePadding, y: v.footerY },
        end: { x: v.pagePadding + v.lineWidth, y: v.footerY },
        thickness: v.lineSize,
    })
    page.drawText('員工簽名', {
        ...draw.text,
        x: v.pagePadding + v.lineWidth - v.fontSize * 4,
        y: v.footerY - v.fontSize - 5,
    })
    page.drawLine({
        start: { x:  width - v.pagePadding - v.lineWidth, y: v.footerY },
        end: { x: width - v.pagePadding, y: v.footerY },
        thickness: v.lineSize,
    })
    page.drawText('日期', {
        ...draw.text,
        x: width - v.pagePadding - v.fontSize * 2,
        y: v.footerY - v.fontSize - 5,
    })

    // to Blob
    const pdfBytes = await pdf.save()
    const bytes = new Uint8Array(pdfBytes)
    const blob = new Blob([bytes], { type: 'application/pdf' })
    const url = URL.createObjectURL(blob)
    // download function
    const downloadPdf = () => {
        const link = document.createElement('a')
        link.href = url
        link.download = 'Checklist report.pdf'
        link.click()
    }

    return {
        url,
        downloadPdf,
    }
}

export default createPdf